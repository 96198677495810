import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Reactmarkdown from "react-markdown"
import Layout from "../../components/layout"
import * as style from "./index.module.css"
import SEO from "../../components/seo"

const query = graphql`
  query {
    allStrapiArticle(sort: { fields: published_at, order: DESC }) {
      edges {
        node {
          strapiId
          tytul
          tekst
          published_at
        }
      }
    }
  }
`

const IndexPagePrimary = () => (
  <Layout>
    <SEO title="Strona główna - ZSP Nidek" />
    <h1 className={style.newsTitle}>Aktualności</h1>
    <div>
      <StaticQuery
        query={query}
        render={data => (
          <>
            {data.allStrapiArticle.edges.map(article => (
              <article key={article.node.strapiId} className={style.article}>
                <h2>{article.node.tytul}</h2>
                <Reactmarkdown
                  transformImageUri={uri =>
                    uri.startsWith("http")
                      ? uri
                      : `${process.env.IMAGE_BASE_URL}${uri}`
                  }
                  source={article.node.tekst}
                />
              </article>
            ))}
          </>
        )}
      />
    </div>
  </Layout>
)

export default IndexPagePrimary
